import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/modules/auth';
import { LocationService } from '../../_services/location.service';
import Swal from 'sweetalert2'; 
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recursos-humanos-personal-checador',
  templateUrl: './recursos-humanos-personal-checador.component.html',
  styleUrls: ['./recursos-humanos-personal-checador.component.scss']
})
export class RecursosHumanosPersonalChecadorComponent implements OnInit {
  title = 'geolocation';
  latitude: any;
  longitude: any;
  records: any[];
  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  constructor(
    private http: HttpClient,
    public locationService: LocationService,
    private changeDetectorRefs: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    public modal: NgbActiveModal
  ) { }
 
  ngOnInit(): void {
    let location = this.getLocation();
    this.getRecords();
  }
  getLocation() {
    this.locationService.getPosition().then(pos => { 
      this.latitude = pos.lat;
      this.longitude = pos.lng; 
      this.changeDetectorRefs.detectChanges();
    });
  }
  getRecords(){
    console.log("ENTRA");
    const promise = new Promise((resolve, reject) => {
      this.http
        .get<any>(`${environment.urlRH}/record/all/`+this.authService.getUser().id)
        .toPromise()
        .then(
          (res: any) => {
            this.records = res;
            this.changeDetectorRefs.detectChanges();
          },
          (err) => { 
            console.log(err);
            Swal.fire("Error", err, "error");
          }
        );
    });
  }
  checkIn(){ 
    const promise = new Promise((resolve, reject) => {
      this.http
        .post<any>(`${environment.urlRH}/record`, {
          personal_id: this.authService.getUser().id,
          lng: this.longitude,
          lat: this.latitude,
          media_id: 3
        })
        .toPromise()
        .then(
          (res: any) => {
            //success
            console.log(res);

            if (res) { 
              Swal.fire("¡Éxito!", "Se ha registrado la asistencia correctamente.", "success");
            } else { 
              Swal.fire("Error", res, "error");
            }
          },
          (err) => { 
            console.log(err);
            Swal.fire("Error", err, "error");
          }
        );
    });
  } 
  triggerSnapshot(): void {
    this.trigger.next();
   }
   async handleImage(webcamImage: WebcamImage) { 
    let formData = new FormData();
    var blob = this.dataURItoBlob(webcamImage.imageAsDataUrl);
    formData.append("img", blob);
    formData.append("id", this.authService.getUser().id);
    formData.append("lng",  this.longitude);
    formData.append("lat", this.latitude);  
    formData.append("entrada", this.authService.getUser().inicio_jornada.split("-")[1]);
    formData.append("salida", this.authService.getUser().fin_jornada.split("-")[1]);  
    this.http.post(`${environment.urlRH}/upload/checker`,formData, {responseType: 'text'}).subscribe(data => {
     
      if(data){
        Swal.fire("Erus", data, "warning");
        this.modal.close(); 
      }
    }); 
 
     
    this.webcamImage = webcamImage;
   }
    
   public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
   }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


  }
  
}